import React, { useRef, useEffect, useState, useContext } from 'react'
import { Reel, RenderControls } from '@hub/reel'
import { CoreContainerContext } from '@hub/core-container'
import { Imposter } from '@hub/imposter'
import { ArrowLeftSmallIcon, ArrowRightSmallIcon } from '@hub/icon'
import { IconButton } from '@hub/button'
import {
  useToken,
  forwardRef,
  // useBreakpointValueClientOnly looks unavoidable here due to the renderReelControls logic
  // eslint-disable-next-line no-restricted-syntax
  useBreakpointValueClientOnly,
} from '@hub/design-system-base'
import type { As, StandardSizes, HubStyleObject } from '@hub/design-system-base'
import debounce from 'lodash/debounce'
import { useWithIsAboveFoldProvider } from '../is-above-fold-provider'
import trackClickEvents, { categories, labels } from '../analytics/trackEvents'
import { InlineDeckItem } from './inline-deck-item'
import { TrackBox } from '../../analytics-observer'

const MAX_MOBILE_VISIBLE_ITEM = 1
const MAX_DESKTOP_VISIBLE_ITEM = 3
const MOBILE_PEEKWIDTH = 0.2
const DESKTOP_PEEKWIDTH = 0
const MAX_VISIBLE_ITEM = [
  MAX_MOBILE_VISIBLE_ITEM,
  MAX_MOBILE_VISIBLE_ITEM,
  MAX_DESKTOP_VISIBLE_ITEM,
  MAX_DESKTOP_VISIBLE_ITEM,
]
const PEEKWIDTH = [
  MOBILE_PEEKWIDTH,
  MOBILE_PEEKWIDTH,
  DESKTOP_PEEKWIDTH,
  DESKTOP_PEEKWIDTH,
]

// We can't use undefined or null for uncalculated values because they
// have specific meanings in the context of HubResponsiveValues
const uncalculated = Symbol('uncalculated')
type Uncalculated = typeof uncalculated

export type Items = React.ComponentProps<typeof InlineDeckItem>

interface ReelButtonProps {
  sx?: HubStyleObject
  onClick: React.MouseEventHandler
  icon: React.ComponentProps<typeof IconButton>['icon']
  'aria-label': string
  as?: As
}

const buttonSizeToken = 'size-8'

export const ReelButton = forwardRef<ReelButtonProps, typeof IconButton>(
  ({ sx, onClick, icon, as, ...rest }, ref) => (
    <IconButton
      ref={ref}
      aria-label={rest['aria-label']}
      sx={{
        borderRadius: '50%',
        boxShadow: '0px 12px 24px 0px rgba(0, 0, 0, 0.15)',
        width: buttonSizeToken,
        height: buttonSizeToken,
        minWidth: buttonSizeToken,
        ...sx,
      }}
      icon={icon}
      as={as}
      colorScheme="surfacePrimaryWithIcon"
      variant="solid"
      aria-hidden="true"
      onClick={onClick}
    />
  )
)

type ButtonAlignmentX = 'edge' | 'center' | Uncalculated

const renderReelControls: ({
  buttonSize,
  buttonOffsetY,
  buttonAlignmentX,
}: {
  buttonSize: StandardSizes
  buttonOffsetY: string | Uncalculated
  buttonAlignmentX: ButtonAlignmentX
}) => RenderControls =
  ({ buttonSize, buttonOffsetY, buttonAlignmentX }) =>
  // eslint-disable-next-line react/display-name
  ({
    hasOverflowingFrames,
    children,
    scrollNext,
    scrollPrev,
    scrollPosition,
  }) => {
    if (
      buttonOffsetY === uncalculated ||
      buttonAlignmentX === uncalculated ||
      !hasOverflowingFrames
    ) {
      return children
    }
    return (
      <Imposter
        impose={
          <ReelButton
            aria-label="Scroll to next"
            sx={{
              display: ['none', null, 'flex'],
              visibility: scrollPosition === 'end' ? 'hidden' : 'visible',
              zIndex: 1,
            }}
            icon={<ArrowRightSmallIcon />}
            onClick={() => {
              scrollNext()
              if (scrollPosition !== 'end') {
                trackClickEvents.clicked(
                  categories.INLINE_DECK,
                  labels.NEXT_CLICK
                )
              }
            }}
          />
        }
        position="top-right"
        marginTop={`calc(${buttonOffsetY} - ${buttonSize} / 2)`}
        marginRight={
          buttonAlignmentX === 'center' ? `calc(${buttonSize} / -2)` : undefined
        }
      >
        <Imposter
          impose={
            <ReelButton
              aria-label="Scroll to previous"
              sx={{
                display: ['none', null, 'flex'],
                visibility: scrollPosition === 'start' ? 'hidden' : 'visible',
                zIndex: 1,
              }}
              icon={<ArrowLeftSmallIcon />}
              onClick={() => {
                scrollPrev()
                if (scrollPosition !== 'start') {
                  trackClickEvents.clicked(
                    categories.INLINE_DECK,
                    labels.PREVIOUS_CLICK
                  )
                }
              }}
            />
          }
          position="top-left"
          marginTop={`calc(${buttonOffsetY} - ${buttonSize} / 2)`}
          marginLeft={
            buttonAlignmentX === 'center'
              ? `calc(${buttonSize} / -2)`
              : undefined
          }
        >
          {children}
        </Imposter>
      </Imposter>
    )
  }

function useButtonYOffset(): {
  elementButtonRelativeTo: React.Ref<HTMLElement>
  buttonOffsetY: string | Uncalculated
} {
  const [buttonOffsetY, setButtonOffsetY] = useState<string | Uncalculated>(
    uncalculated
  )
  const setHeight = (height: number | undefined | null): void =>
    height ? setButtonOffsetY(`${Math.floor(height / 2)}px`) : undefined
  const elementButtonRelativeTo = useRef<HTMLElement>(null)
  useEffect(() => {
    setHeight(elementButtonRelativeTo?.current?.clientHeight)
    if (!window.ResizeObserver) {
      return undefined
    }
    const resizeHandler = debounce(
      (entries: ResizeObserverEntry[]) =>
        setHeight(entries[0].contentRect.height),
      100,
      { leading: true, trailing: true }
    )

    const resizeObserver = new ResizeObserver(resizeHandler)

    if (elementButtonRelativeTo?.current) {
      resizeObserver.observe(elementButtonRelativeTo?.current)
    }

    return () => {
      resizeHandler.cancel()
      resizeObserver.disconnect()
    }
  }, [])
  return { buttonOffsetY, elementButtonRelativeTo }
}

export const InlineDeckReel: React.FC<
  React.PropsWithChildren<{
    items: Items[]
    elevateHeading?: boolean
    clickEvent: (index: number) => void
  }>
> = ({ items, elevateHeading, clickEvent }) => {
  const buttonAlignmentX = useBreakpointValueClientOnly<ButtonAlignmentX>(
    ['edge', 'edge', 'center', 'center'],
    uncalculated
  )
  const buttonSize = useToken('sizes', buttonSizeToken)
  const container = useContext(CoreContainerContext)
  const trackBoxWithFold = useWithIsAboveFoldProvider(TrackBox)
  const { buttonOffsetY, elementButtonRelativeTo } = useButtonYOffset()
  return (
    <Reel
      snap
      gap={['spacing-sm', null, 'spacing-md']}
      containerOverflow={[container.gutter, null, 'spacing-none']}
      visibleFrames={MAX_VISIBLE_ITEM}
      peekWidth={PEEKWIDTH}
      shouldWrapChildren={false}
      renderControls={renderReelControls({
        buttonSize,
        buttonOffsetY,
        buttonAlignmentX,
      })}
    >
      {items.map((item, index) => {
        const TrackBoxWithFold = trackBoxWithFold(
          index < MAX_DESKTOP_VISIBLE_ITEM ? 'above' : 'below'
        )
        return (
          <TrackBoxWithFold
            key={`item-${index}`}
            sx={{
              maxWidth: 'size-19',
              flexShrink: 1,
              flexBasis: 'size-19',
            }}
          >
            <InlineDeckItem
              imageRef={index === 0 ? elementButtonRelativeTo : null}
              {...item}
              elevateHeading={elevateHeading}
              onClick={() => {
                clickEvent(index)
                trackClickEvents.clicked(
                  categories.INLINE_DECK,
                  `${item.title} | P${index + 1}`
                )
              }}
            />
          </TrackBoxWithFold>
        )
      })}
    </Reel>
  )
}
