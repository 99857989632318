import React from 'react'
import { SGPageTemplateFlexibleGrid } from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../types'
import { CoreContainer } from '@hub/core-container'
import {
  FlexibleGrid,
  FlexibleGridItem,
} from '@scentregroup/shared/hub-components/flexible-grid'

export default function SGPageTemplateFlexibleGrifModule({
  module: { items, maxColumns },
}: ModuleProps<SGPageTemplateFlexibleGrid>): JSX.Element | null {
  if (!items) {
    return null
  }

  if (maxColumns !== 2 && maxColumns !== 3) {
    console.log('invalid maxColumns value for flexible grid: ', maxColumns)
    return null
  }

  return (
    <CoreContainer>
      <FlexibleGrid maxColumns={maxColumns}>
        {items.map((item, index) => (
          <FlexibleGridItem
            key={`flex-grid-item-${index + 1}`}
            bodyCopy={item.bodyCopy}
            image={item.image}
          />
        ))}
      </FlexibleGrid>
    </CoreContainer>
  )
}
