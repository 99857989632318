import { ApolloClient } from '@apollo/client'
import { GetNationalExperiencePageTemplateDocument } from '@scentregroup/shared/graphql'
import { SGPageTemplateWithAdZones } from '@scentregroup/shared/types/page-templates'
import parse from '../parsing'

export async function getNationalExperiencePage<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  country: string,
  slug: string
): Promise<SGPageTemplateWithAdZones | null> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetNationalExperiencePageTemplateDocument,
    variables: {
      country,
      slug,
    },
  })

  if (!data?.template) {
    return null
  }
  return {
    ...parse(data.template),
    metaTitle: data.template.metaTitle || data.template.title,
  }
}
