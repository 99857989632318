import React from 'react'
import { CoreContainer } from '@hub/core-container'
import {
  As,
  DEFAULT_BREAKPOINT,
  HubResponsiveArray,
  useBreakpointValue,
} from '@hub/design-system-base'
import { Link } from '@hub/link'
import { SGPageTemplateImage } from '@scentregroup/shared/types/page-templates'
import { CloudinaryImage, IMAGE_SIZES_FULL_WIDTH } from '../cloudinary-image'

interface BannerProps {
  url: string
  image: SGPageTemplateImage
  onClick: () => void
  mobileImage?: SGPageTemplateImage | null
  gutter?: HubResponsiveArray<string>
  as?: As
}

export const Banner: React.FC<React.PropsWithChildren<BannerProps>> = ({
  url,
  image,
  onClick,
  mobileImage,
  as,
  gutter,
}) => {
  const isMobile =
    useBreakpointValue([true, false, false], DEFAULT_BREAKPOINT) ?? false

  const [bestImage, ratio] = getImageAndRatio(isMobile, image, mobileImage)

  return (
    <CoreContainer
      isMobileFullWidth
      gutter={gutter}
      sx={{ textAlign: '-webkit-center' }}
      as={as}
    >
      <Link href={url} onClick={onClick} ariaLabel={`${image.alt}`}>
        <CloudinaryImage
          imageSetOrImage={bestImage}
          ratio={ratio}
          resizeMode="crop-center"
          sizes={IMAGE_SIZES_FULL_WIDTH}
        />
      </Link>
    </CoreContainer>
  )
}

function getImageAndRatio(
  isMobile: boolean,
  image: SGPageTemplateImage,
  mobileImage?: SGPageTemplateImage | null
): [image: SGPageTemplateImage, ratio: HubResponsiveArray<string | number>] {
  const ratio = isMobile && !mobileImage ? '3/2' : ['3/2', '4.6/1']
  const bestImage = getBestImage(isMobile, image, mobileImage)
  return [bestImage, ratio]
}

function getBestImage(
  isMobile: boolean,
  image: SGPageTemplateImage,
  mobileImage?: SGPageTemplateImage | null
): SGPageTemplateImage {
  if (!mobileImage) {
    return image
  }
  return isMobile ? mobileImage : image
}
