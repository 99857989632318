import React from 'react'
import { SGPageTemplateRichTextContent } from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../types'
import RichText from '@scentregroup/shared/hub-components/rich-text'
import { Box } from '@hub/box'

export default function RichTextContent({
  module,
}: ModuleProps<SGPageTemplateRichTextContent>): JSX.Element {
  const { bodyCopy } = module

  return (
    <Box className="module-rich-text">
      <RichText body={bodyCopy} />
    </Box>
  )
}
