import { HubResponsiveArray, StandardSpacings } from '@hub/design-system-base'
import React, { createContext } from 'react'

export const GapContext: React.Context<{
  gapX: HubResponsiveArray<StandardSpacings>
  gapY: HubResponsiveArray<StandardSpacings>
}> = createContext({
  gapX: 'spacing-none' as HubResponsiveArray<StandardSpacings>,
  gapY: 'spacing-none' as HubResponsiveArray<StandardSpacings>,
})
