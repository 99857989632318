import { ApolloClient } from '@apollo/client'
import { GetNationalPageTemplateDocument } from '@scentregroup/shared/graphql'
import { SGPageTemplateWithAdZones } from '@scentregroup/shared/types/page-templates'
import parse from '../parsing'

export async function getNationalPageTemplate<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  country: string,
  path: string
): Promise<SGPageTemplateWithAdZones | null> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetNationalPageTemplateDocument,
    variables: {
      country,
      path,
    },
  })

  if (!data?.template) {
    return null
  }
  return parse(data.template)
}
