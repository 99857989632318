/* eslint jsx-a11y/no-redundant-roles: 0 */
import React from 'react'
import classNames from 'classnames'
import { Paragraph } from '@scentregroup/ui'
import { summariseOccurrences } from '../../../helpers/occurrences'
import AutoPotentialLink from '../../auto-potential-link'
import ResponsiveImage from '../../responsive-image'
import Trackable from '../../trackable'
import { trackingActions, trackingTypes } from '../../../constants'
import { DeckCardProps } from '../../../../../apps/website/components/page-templates/modules/content-collection/whats-happening-deck'
import style from './index.module.scss'

export interface DeckCardProp extends DeckCardProps {
  title?: string
  className?: string
  externalLink?: boolean
  occurrences?: {
    summary: boolean
    times: {
      startAt: string
      endAt: string
    }[]
    timeZone: string
  }
  location?: string
  onClick?: () => void
  clickEvent?: (index: number) => void
  padded?: boolean
  trackers?: {
    clicked: (body: string) => void
  }
  index: number
}

const DeckCard = ({
  className,
  body,
  image,
  link,
  externalLink,
  occurrences,
  location,
  onClick,
  clickEvent,
  analyticsMetadata,
  padded = false,
  trackers,
  index,
}: DeckCardProp): JSX.Element => {
  const hasOccurrences = Boolean(
    occurrences && occurrences.times && occurrences.times.length
  )
  return (
    <li
      className={classNames(
        style.DeckCard,
        style.evolved,
        { [style.padded]: padded },
        className
      )}
      role="listitem"
      data-test-id="card"
    >
      <Trackable
        {...analyticsMetadata}
        trackingAction={trackingActions.viewPromotion}
        type={trackingTypes.promotion}
        creative={analyticsMetadata?.promotion?.creative}
      >
        <AutoPotentialLink
          link={link}
          className={style.link}
          external={externalLink}
          onClick={() => {
            onClick?.()
            trackers?.clicked?.(body)
            if (clickEvent) {
              clickEvent(index ?? 0)
            }
          }}
        >
          <ResponsiveImage
            className={style.image}
            desktopImageUrl={image}
            mobileImageUrl={image}
            mobileRatio="square"
            desktopRatio="square"
            numberOfItems={4}
            alt=""
            dataTestId="card-image"
          />
          <div className={style.cardBody}>
            <div className={style.cardInner} data-test-id="card-details">
              {(hasOccurrences || location) && (
                <div className={style.metaWrapper}>
                  {hasOccurrences && (
                    <Paragraph className={style.meta}>
                      {summariseOccurrences(
                        occurrences?.times,
                        occurrences?.timeZone
                      )}
                    </Paragraph>
                  )}
                  {location && (
                    <Paragraph className={style.meta}>{location}</Paragraph>
                  )}
                </div>
              )}
              <Paragraph className={style.description}>{body}</Paragraph>
            </div>
          </div>
        </AutoPotentialLink>
      </Trackable>
    </li>
  )
}

export default DeckCard
