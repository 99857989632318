import { ApolloClient } from '@apollo/client'
import { GetCentrePageTemplateDocument } from '@scentregroup/shared/graphql'
import { SGPageTemplateWithAdZones } from '@scentregroup/shared/types/page-templates'
import parse from '../parsing'
import { get } from 'lodash'

export async function getCentrePageTemplate<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  centreSlug: string,
  path: string
): Promise<SGPageTemplateWithAdZones | null> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetCentrePageTemplateDocument,
    variables: {
      centreSlug,
      path,
    },
  })

  if (!data?.template) {
    return null
  }

  return parse(
    data.template,
    get(data, 'cfCentreCollection.items[0].title', undefined)
  )
}
