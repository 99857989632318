import React from 'react'
import { useToken } from '@hub/design-system-base'
import type { RenderControlsArgs } from '@hub/reel'
import { IconButton } from '@hub/button'
import Color from 'color'

interface ArrowButtonProps {
  icon: React.ComponentProps<typeof IconButton>['icon']
  ariaLabel: string
}

export const ArrowButton: React.FC<
  React.PropsWithChildren<
    ArrowButtonProps & RenderControlsArgs['prevComponentProps']
  >
> = ({ icon, ariaLabel, ...props }) => {
  const buttonBackgroundColor = Color(useToken('colors', 'licorice'))
    .alpha(0.5) // eslint-disable-line no-magic-numbers
    .rgb()
    .string()
  return (
    <IconButton
      icon={icon}
      aria-label={ariaLabel}
      sx={{
        color: 'white',
        minWidth: 0,
        height: 'auto',
        padding: 'spacing-sm',
        background: buttonBackgroundColor,
        borderRadius: 'full',
        '&:hover': {
          backgroundColor: buttonBackgroundColor,
          '& > svg': { opacity: 0.8 },
          color: 'ghost',
        },
      }}
      {...props}
    />
  )
}
