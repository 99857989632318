import { css, useTheme } from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  mapRules,
  HubResponsiveArray,
  StandardSizes,
  forwardRef,
} from '@hub/design-system-base'
import { Box } from '@hub/box'
import { get } from 'lodash'
import React, { useContext } from 'react'
import { GapContext } from './gap-context'

interface ClusterItemProps {
  width?: HubResponsiveArray<StandardSizes>
  sx?: HubStyleObject
  as?: As
}

export const ClusterItem = forwardRef<ClusterItemProps, typeof Box>(
  ({ width, as, sx, children }, ref) => {
    const { gapX } = useContext(GapContext)
    const theme = useTheme()

    let rules = {}

    if (width) {
      // Normalise to dealing with arrays
      let gapXResponsive = Array.isArray(gapX) ? gapX : [gapX]
      let widthResponsive = Array.isArray(width) ? width : [width]

      // Ensure the `gapX` matches the arity of the `width` prop by repeating the
      // last element as many times as is necessary
      if (gapXResponsive.length < widthResponsive.length) {
        const extendedGapX = Array.from(
          { length: widthResponsive.length - gapXResponsive.length },
          () => gapXResponsive[gapXResponsive.length - 1]
        )
        gapXResponsive = [...gapXResponsive, ...extendedGapX]
      } else if (gapXResponsive.length > widthResponsive.length) {
        const extendedWidth = Array.from(
          { length: gapXResponsive.length - widthResponsive.length },
          () => widthResponsive[widthResponsive.length - 1]
        )
        widthResponsive = [...widthResponsive, ...extendedWidth]
      }

      // For the next calculation, we have to ensure that every breakpoint
      // actually has a value, so we fill in any `null`s with their explicit value
      // of the base (ie; first element).
      // This is only necessary for gapX since we will skip a breakpoint entirely
      // if it doesn't have a width set.
      gapXResponsive = gapXResponsive.map(
        (value: string | null, index: number) => {
          if (index === 0) {
            return value
          }

          return value ?? gapXResponsive[0]
        }
      )

      // Convert the responsive values to CSS rules
      const marginRules = css({ margin: gapXResponsive })(theme)
      const flexBasisRules = css({ flexBasis: widthResponsive })(theme)

      // Now we go over the rules, ensuring the flexBasis takes into account the
      // width if necessary
      // We also force `!important` due to specificity issues with setting the
      // flexBasis from the parent
      rules = mapRules(flexBasisRules, (rule, _, path) => {
        // a percentage width needs to be calculated.
        // see https://github.com/Heydon/fukol-grids#percentage-widths
        if (rule.indexOf('%') !== -1) {
          const gap = get(marginRules, [...path.slice(0, -1), 'margin'])
          return `calc(${rule} - ${gap}) !important`
        }

        return `${rule} !important`
      })
    }

    return (
      <Box ref={ref} as={as} sx={{ ...rules, ...sx }}>
        {children}
      </Box>
    )
  }
)
