import { useToken } from '@hub/design-system-base'
import { pickBestContrast } from '../hub-components/feature'

const useColorToken = (
  color: string | null | undefined,
  defaultColor: string
): string => {
  return useToken('colors', color || defaultColor, color ?? undefined)
}

export const useSupportingColors = (
  supportingColor?: string | null,
  overrides?: {
    lightForegroundColor?: string
    darkForegroundColor?: string
  }
): {
  supportingForegroundColor: string
  supportingBackgroundColor: string
} => {
  const darkForegroundColorForLightBackgrounds = useColorToken(
    overrides?.darkForegroundColor,
    'textPrimary'
  )
  const lightForegroundColorForDarkBackgrounds = useColorToken(
    overrides?.lightForegroundColor,
    'textSurfacePrimary'
  )
  const supportingBackgroundColor = useColorToken(
    supportingColor,
    'surfaceBrandPrimary'
  )
  const supportingForegroundColor = pickBestContrast(
    supportingBackgroundColor,
    [
      darkForegroundColorForLightBackgrounds,
      lightForegroundColorForDarkBackgrounds,
    ]
  )

  return {
    supportingForegroundColor,
    supportingBackgroundColor,
  }
}
