import React from 'react'
import { Container } from '@scentregroup/ui'
import style from './index.module.scss'

export const MarketplaceDeckContainer: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => (
  <Container type="large" className={style.MarketplaceDeckContainer}>
    {children}
  </Container>
)
