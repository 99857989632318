import React from 'react'
import {
  SGPageTemplateContentCollection,
  SGPageTemplateContentCollectionKind,
} from '@scentregroup/shared/types/page-templates'
import { ModuleComponent, ModuleProps } from '../types'
import SGPageTemplateContentCollectionBannerModule from './content-collection/banner-module'
import SGPageTemplateContentCollectionCategoryGridModule from './content-collection/category-grid'
import SGPageTemplateContentCollectionColourCardsModule from './content-collection/colour-cards'
import SGPageTemplateContentCollectionFeatureModule from './content-collection/feature'
import SGPageTemplateContentCollectionInlineDeckModule from './content-collection/inline-deck'
import SGPageTemplateContentCollectionStoreGridModule from './content-collection/store-grid-module'
import SGPageTemplateContentCollectionUtilityPanelModule from './content-collection/utility-panel-module'
import SGPageTemplateContentCollectionSpotlightModule from './content-collection/spotlight'
import SGPageTemplateContentCollectionWhatsHappeningDeckModule from './content-collection/whats-happening-deck'

export default function SGPageTemplateContentCollectionModule({
  module,
  enableH1Heading: enableH1Heading,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const Component = moduleComponents[module.kind]
  if (!Component) {
    console.log('unsupported content collection kind:', module.kind)
    return null
  }
  return <Component module={module} enableH1Heading={enableH1Heading} />
}
const moduleComponents: {
  [key in SGPageTemplateContentCollectionKind]: ModuleComponent<SGPageTemplateContentCollection>
} = {
  BANNER: SGPageTemplateContentCollectionBannerModule,
  CATEGORY_GRID: SGPageTemplateContentCollectionCategoryGridModule,
  COLOUR_CARDS: SGPageTemplateContentCollectionColourCardsModule,
  FEATURE: SGPageTemplateContentCollectionFeatureModule,
  INLINE_DECK: SGPageTemplateContentCollectionInlineDeckModule,
  STORE_GRID: SGPageTemplateContentCollectionStoreGridModule,
  UTILITY_PANEL: SGPageTemplateContentCollectionUtilityPanelModule,
  SPOTLIGHT: SGPageTemplateContentCollectionSpotlightModule,
  WHATS_HAPPENING_DECK: SGPageTemplateContentCollectionWhatsHappeningDeckModule,
}
