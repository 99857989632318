import React from 'react'
import { SGPageTemplateDynamicContent } from '@scentregroup/shared/types/page-templates'
import { ModuleComponent, ModuleProps } from '../types'

export default function SGPageTemplateDynamicContentModule({
  module,
}: ModuleProps<SGPageTemplateDynamicContent>): JSX.Element | null {
  const Component = moduleComponents[module.kind]
  if (!Component) {
    console.log('unsupported dynamic content kind:', module.kind)
    return null
  }
  return <Component module={module} />
}

const moduleComponents: {
  [key in SGPageTemplateDynamicContent['kind']]: ModuleComponent<SGPageTemplateDynamicContent>
} = {}
