import { PageTemplateFragment } from '@scentregroup/shared/graphql'
import * as Sentry from '@sentry/nextjs'
import {
  SGPageTemplateContentEntry,
  SGPageTemplateImage,
} from '@scentregroup/shared/types/page-templates'

type SGPageTemplateFragmentModule = PageTemplateFragment['modules'][0]

type SGPageTemplateFragmentContentCollectionModule = Extract<
  SGPageTemplateFragmentModule,
  { __typename: 'SGPageTemplateContentCollection' }
>

type SGPageTemplateFragmentContentCollectionModuleItem =
  SGPageTemplateFragmentContentCollectionModule['items'][0]

type SGPageTemplateFragmentContentCollectionModuleItemContentEntry = Extract<
  SGPageTemplateFragmentContentCollectionModuleItem,
  { __typename: 'SGPageTemplateContentEntry' }
>

export function parseContentEntry(
  item: SGPageTemplateFragmentContentCollectionModuleItemContentEntry
): SGPageTemplateContentEntry | null {
  const image = parseImage(item.image)
  if (!image) {
    return null
  }

  let secondaryImage = null
  if (item.secondaryImage) {
    secondaryImage = parseImage(item.secondaryImage)
  }

  if (!item.analyticsMetadata?.correlationId) {
    Sentry.captureMessage(
      `No correlationId found for ${item.title}, this shouldn't ever be undefined something must be wrong`,
      'warning'
    )
  }

  return {
    __typename: 'SGPageTemplateContentEntry',
    kind: item.kind,
    title: item.title,
    image,
    secondaryImage,
    shortDescription: item.shortDescription ?? null,
    callToAction: item.callToAction,
    textColour: item.textColour ?? null,
    adConfig: item.adConfig ?? null,
    supplementaryText: item.supplementaryText ?? null,
    memberExclusive: Boolean(item.memberExclusive),
    highlightAttribute: item.highlightAttribute ?? null,
    ...(item.analyticsMetadata?.correlationId && item.analyticsMetadata?.title
      ? {
          analyticsMetadata: {
            correlationId: item.analyticsMetadata?.correlationId,
            promotion: {
              creative: item.analyticsMetadata?.promotion?.creative ?? null,
            },
            title: item.analyticsMetadata?.title,
          },
        }
      : {}),

    ...(item.orchestrationAnalyticsMetadata
      ? {
          orchestrationMetadata: item.orchestrationAnalyticsMetadata,
        }
      : {}),
  }
}

function parseImage(
  hi: SGPageTemplateFragmentContentCollectionModuleItemContentEntry['image']
): SGPageTemplateImage | null {
  const { type, url } = hi
  if (!type || !url) {
    return null
  }
  return {
    type,
    url,
    alt: hi.alt ?? null,
    supportingColour: hi.supportingColour ?? null,
    cinemagraphUrl: hi.cinemagraphUrl ?? null,
  }
}
