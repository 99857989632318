import { HubStyleObject, useToken, As } from '@hub/design-system-base'
import { H3 } from '@hub/heading'
import { Imposter } from '@hub/imposter'
import { LinkBox, LinkOverlay } from '@hub/link'
import React from 'react'

import { SGPageTemplateContentEntry } from '@scentregroup/shared/types/page-templates'
import trackClickEvents, { categories } from '../analytics/trackEvents'
import {
  CloudinaryImage,
  IMAGE_SIZES_TILE_PRIMARY,
  IMAGE_SIZES_TILE_SECONDARY,
} from '../cloudinary-image'

export const CategoryTile: React.FC<
  React.PropsWithChildren<
    SGPageTemplateContentEntry & { sx?: HubStyleObject; as?: As }
  >
> = ({ sx, title, image, callToAction, as }) => {
  const [sizeMd, sizeHalf] = useToken('sizes', ['size-md', 'size-half'])
  const primaryOrderPosition = 2
  const isPrimary =
    Array.isArray(sx?.order) && sx?.order[1] === primaryOrderPosition
  return (
    <LinkBox
      sx={sx}
      onClick={() =>
        trackClickEvents.clicked(
          categories.CATEGORY_DECK,
          `Top Category | ${title}`
        )
      }
    >
      <Imposter
        as={as}
        sx={sx}
        impose={
          <H3
            color="white"
            align="center"
            sx={{
              width: 'size-full',
              height: 'size-full',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              paddingBottom: 'spacing-sm',
              '&::before': {
                content: "''",
                display: 'block',
                position: 'absolute',
                bottom: 0,
                backgroundImage:
                  'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 100%)',
                height: `min(${sizeMd}, ${sizeHalf})`,
                width: 'size-full',
              },
            }}
          >
            <LinkOverlay
              href={callToAction.url}
              sx={{ zIndex: 'elevatedContent', paddingX: 'spacing-xs' }}
            >
              {title}
            </LinkOverlay>
          </H3>
        }
        position="center"
      >
        <CloudinaryImage
          ratio="1"
          imageSetOrImage={image}
          sizes={
            isPrimary ? IMAGE_SIZES_TILE_PRIMARY : IMAGE_SIZES_TILE_SECONDARY
          }
        />
      </Imposter>
    </LinkBox>
  )
}
