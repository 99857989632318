import React from 'react'
import { SGPageTemplateDownloadWestfieldApp } from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../types'
import { DownloadWestfieldApp } from '@scentregroup/shared/hub-components/download-westfield-app'
import RichText from '@scentregroup/shared/hub-components/rich-text'

export default function DownloadWestfieldAppContent({
  module,
}: ModuleProps<SGPageTemplateDownloadWestfieldApp>): JSX.Element {
  const { __typename, bodyCopy, image } = module

  return (
    <DownloadWestfieldApp
      image={
        image && {
          src: image.url,
          alt: image.alt ?? '',
        }
      }
    >
      <RichText body={bodyCopy} />
    </DownloadWestfieldApp>
  )
}
