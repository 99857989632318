import { ApolloClient } from '@apollo/client'
import { GetNationalHomepagePageTemplateDocument } from '@scentregroup/shared/graphql'
import { SGPageTemplateWithAdZones } from '@scentregroup/shared/types/page-templates'
import parse from '../parsing'

export async function getNationalHomepage<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  country: string
): Promise<SGPageTemplateWithAdZones | null> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetNationalHomepagePageTemplateDocument,
    variables: {
      country,
    },
  })

  if (!data?.template) {
    return null
  }
  return parse(data.template)
}
