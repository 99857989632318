import { Box } from '@hub/box'
import React from 'react'
import { DirectLogoMediumIcon } from './direct-logo-medium'

interface IncludeDirectLogoProps {
  title: string
}

export const IncludeDirectLogo: React.FC<React.PropsWithChildren<IncludeDirectLogoProps>> = ({
  title,
}) => {
  const firstUsageLogoPlaceholder = '|||'
  const [beforeLogo, ...afterLogo] = title
    .replace(/Westfield Direct/, firstUsageLogoPlaceholder)
    .split(firstUsageLogoPlaceholder)
  if (afterLogo.length === 0) {
    return <>{beforeLogo}</>
  }
  return (
    <span aria-label={title}>
      {beforeLogo}
      <Box as="span" sx={{ display: 'inline-flex' }} aria-hidden="true">
        <DirectLogoMediumIcon
          sx={{
            width: 'auto',
            flex: '1 1 100%',
            position: 'relative',
            top: '0.2em',
            color: 'blueCharchoal',
            '& .chevron': {
              color: 'vibrantTeal',
            },
          }}
          // assumes line-height of surrounding text
          boxSize="size-icon-line-height-shorter"
        />
      </Box>
      {afterLogo.join('')}
    </span>
  )
}
