import React from 'react'
import { Box } from '@hub/box'
import type { RenderControls } from '@hub/reel'
import { ButtonGroup } from '@hub/button'
import { Imposter } from '@hub/imposter'
import { ChevronLeftSmallIcon, ChevronRightSmallIcon } from '@hub/icon'
import trackClickEvents, { categories, labels } from '../analytics/trackEvents'
import { Dots } from './dots'
import { ArrowButton } from './arrowButton'

export const FeatureControls: (breakpoint: string) => RenderControls =
  breakpoint =>
  // eslint-disable-next-line react/display-name
  ({
    scrollNext,
    scrollPrev,
    children,
    totalFrames,
    scrollPosition,
    scrollIndex,
  }) => {
    if (totalFrames === 1) {
      return children
    }
    if (breakpoint === 'sm' || breakpoint === 'base') {
      return (
        <Imposter
          impose={
            // To ensure the dots are positioned relative to the bottom of the image,
            // we take advantage of the fact the image is full-width 1:1 ratio on this screen size,
            // then we use the padding-top trick to move it down by 100% of the width of the image
            // (which is also 100% the height of the image), minus a few pixels to sit it on the image
            // instead of immediately below it
            <Box
              sx={{
                // Not clickable / tappable on mobile. Ensures taps there fall
                // through onto the card below it which is an <a> tag
                pointerEvents: 'none',
                paddingTop: 'calc(100% - 16px)',
              }}
            >
              <Dots
                totalFrames={totalFrames}
                scrollIndex={scrollIndex}
                sx={{
                  paddingX: 'spacing-md',
                  paddingY: 'spacing-sm',
                  borderRadius: 'full',
                }}
              />
            </Box>
          }
          position="top"
        >
          {children}
        </Imposter>
      )
    }
    return (
      <Imposter
        impose={
          <ButtonGroup isAttached sx={{ alignItems: 'center', zIndex: 1 }}>
            <ArrowButton
              icon={<ChevronLeftSmallIcon />}
              ariaLabel="Go To Previous Slide"
              onClick={() => {
                scrollPrev()
                if (scrollPosition !== 'start') {
                  trackClickEvents.clicked(
                    categories.FEATURE_CAROUSEL,
                    labels.PREVIOUS_CLICK
                  )
                }
              }}
            />
            <Dots totalFrames={totalFrames} scrollIndex={scrollIndex} />
            <ArrowButton
              icon={<ChevronRightSmallIcon />}
              ariaLabel="Go To Next Slide"
              onClick={() => {
                scrollNext()
                if (scrollPosition !== 'end') {
                  trackClickEvents.clicked(
                    categories.FEATURE_CAROUSEL,
                    labels.NEXT_CLICK
                  )
                }
              }}
            />
          </ButtonGroup>
        }
        position="bottom"
        marginBottom="spacing-md"
      >
        {children}
      </Imposter>
    )
  }
