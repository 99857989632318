import analyticsUtils from '../../../helpers/analytics-utils'

export const trackClickEvent = (
  cardTitle?: string,
  spotlightTitle?: string
): void => {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.PROMOTION_CLICK,
    ecommerce: {
      promoClick: {
        promotions: {
          id: spotlightTitle,
          name: analyticsUtils.names.SPOTLIGHT,
          creative: cardTitle,
          position: analyticsUtils.positions.SPOTLIGHT,
        },
      },
    },
  })
}
