import React from 'react'
import { Link } from '@hub/link'
import trackClickEvent from '../../../helpers/analytics-utils/track-event'
import EventNotification from '../../../components/event-notification'
import { SpotlightModuleProps, SpotlightEntriesProps } from '../types'
import { H3 } from '@hub/heading'
import { Box } from '@hub/box'
import { useCountry } from '@scentregroup/shared/country'

export const isService = (
  entries: SpotlightEntriesProps[],
  contentTypes?: string[]
): boolean => {
  return Boolean(
    contentTypes?.includes('Service') ||
      entries?.find(entry => entry.contentTypeId === 'service')
  )
}

const showCovidNotification = (
  country: string,
  entries: SpotlightEntriesProps[],
  contentTypes?: string[]
): boolean => {
  return country === 'Australia' && isService(entries, contentTypes)
}

interface SpotlightProps extends SpotlightModuleProps {
  contentTypes?: string[]
}

interface NotificationProps {
  shortDescription: string
  entries: SpotlightEntriesProps[]
  contentTypes?: string[]
}

const Notification: React.FC<NotificationProps> = ({
  shortDescription,
  entries,
  contentTypes,
}) => {
  const country = useCountry()
  return (
    <>
      {shortDescription &&
        showCovidNotification(country, entries, contentTypes) && (
          <EventNotification
            sx={{
              color: 'quartz',
              textAlign: 'center',
              fontSize: ['font-sm', null, 'font-md'],
              marginTop: '-spacing-sm',
              marginBottom: 'spacing-sm',
            }}
            message={shortDescription}
          />
        )}
    </>
  )
}

const Spotlight: React.FC<React.PropsWithChildren<SpotlightProps>> = ({
  children,
  heading,
  shortDescription,
  callToAction,
  contentTypes,
  entries,
}) => (
  <Box
    sx={{
      paddingY: ['spacing-none', null, null],
    }}
  >
    <H3 align={'center'} sx={{ marginBottom: ['spacing-md', 'spacing-lg'] }}>
      {heading}
    </H3>
    <Notification
      shortDescription={shortDescription}
      contentTypes={contentTypes}
      entries={entries}
    />
    {children}
    {callToAction && callToAction.address && (
      <Box
        sx={{ textAlign: 'center', paddingTop: ['spacing-lg', 'spacing-md'] }}
      >
        <Link
          hasUnderline
          href={callToAction.address}
          onClick={
            callToAction.eventCategory
              ? () =>
                  trackClickEvent(
                    callToAction.eventCategory,
                    callToAction.eventAction,
                    callToAction.eventLabel
                  )
              : () => void {}
          }
        >
          {callToAction.text}
        </Link>
      </Box>
    )}
  </Box>
)

export default Spotlight
