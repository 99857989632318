import { ApolloClient } from '@apollo/client'
import { GetPageTemplateAdZoneContentEntriesDocument } from '@scentregroup/shared/graphql'
import { SGPageTemplateContentEntry } from '@scentregroup/shared/types/page-templates'
import { parseContentEntry } from '../parsing/content-entry'

export async function getPageTemplateAdZoneContentEntries<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  adZoneIds: string[],
  adTargeting?: {
    centreSlug?: string | null
  }
): Promise<(SGPageTemplateContentEntry | null)[]> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetPageTemplateAdZoneContentEntriesDocument,
    variables: {
      adZoneIds,
      centreSlug: adTargeting?.centreSlug || null,
    },
  })

  if (!data?.contentEntries) {
    return []
  }

  return data.contentEntries.map(contentEntry =>
    contentEntry ? parseContentEntry(contentEntry) : null
  )
}
