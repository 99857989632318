import React from 'react'
import { InlineDeck } from '@scentregroup/shared/hub-components/inline-deck'
import { AsMeasured } from '@scentregroup/shared/hub-components/as-measured'
import { useAnalytics } from '@scentregroup/shared/analytics'

import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-empty-function

export default function SGPageTemplateContentCollectionInlineDeckModule({
  module,
  enableH1Heading: enableH1Heading,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  const header =
    (module.title && {
      title: module.title,
      shortDescription: module.shortDescription || undefined,
      callToAction: module.callToAction || undefined,
    }) ||
    undefined

  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(module, index),
    })
  }

  return (
    <InlineDeck
      header={header}
      items={module.items.map(item => ({
        ...item,
        highlightAttribute: item.highlightAttribute,
        // eslint-disable-next-line react/display-name
        as: asProps => (
          <AsMeasured
            {...asProps}
            metadata={item.analyticsMetadata}
            slot={module.analyticsMetadata?.displayId}
          />
        ),
      }))}
      as={asProps => (
        <AsMeasured
          {...asProps}
          moduleKind={module.kind}
          moduleMetadata={module.analyticsMetadata}
        />
      )}
      clickEvent={triggerOnClick}
      enableH1Heading={enableH1Heading}
    />
  )
}
