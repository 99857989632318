/* eslint jsx-a11y/no-redundant-roles: 0 */
import React, { Component } from 'react'
import classNames from 'classnames'
import { Container, Heading } from '@scentregroup/ui'
import { uniqueId } from 'lodash'
import { Link } from '@scentregroup/shared/link'
import DeckScrollWrapper from '../deck-scroll-wrapper'
import showWhatsOnFilter from '../../helpers/show-whats-on-filter/index'
import analyticsUtils from '../../helpers/analytics-utils'
import { rangesForDate } from '../../helpers/time-ranges'
import { getNowInCentreInDateFormat } from '../../helpers/date-helpers'
import style from './index.module.scss'
import DeckCard from './deck-card'
import {
  trackDeckCTAClick,
  trackDeckDateRangeClick,
  trackPromotionClickEvent,
} from './analytics'
interface CentreDetails {
  timeZone: string
  status: string
  slug: string
}
interface DeckProp {
  callToActionLabel?: string
  callToActionAddress?: string
  title?: string
  centre: CentreDetails
  padded?: boolean
}

class Deck extends Component<
  React.PropsWithChildren<DeckProp>,
  { selectedFilter: string }
> {
  state = {
    selectedFilter: '',
  }

  render(): JSX.Element {
    const {
      children,
      title,
      centre,
      callToActionLabel,
      callToActionAddress,
      padded = false,
    } = this.props

    const { selectedFilter } = this.state
    const id = uniqueId('deck-')
    const whatsOnContentId = 'whats-on-content'
    const showCTA = callToActionLabel && callToActionAddress
    const ranges = rangesForDate(
      getNowInCentreInDateFormat(centre.timeZone),
      selectedFilter
    )
    return (
      <Container
        centred
        type="large"
        className={classNames(style.deckContainer, style.evolved)}
      >
        <Heading type="small" className={style.heading} id={id}>
          {title}
        </Heading>
        {centre && showWhatsOnFilter(centre.status) && (
          <div className={style.deckFiltersContainer}>
            <div aria-controls={whatsOnContentId} className={style.whatsOnNav}>
              {ranges.map(entry => (
                <Link
                  className={style.filterLinks}
                  key={entry.slug()}
                  to={`/${centre.slug}/whats-happening?range=${entry.slug()}`}
                  onClick={() => trackDeckDateRangeClick(entry.name)}
                >
                  {entry.name}
                </Link>
              ))}
            </div>
          </div>
        )}

        <DeckScrollWrapper
          className={style.contentWrapper}
          bgColor={'Seasonal'}
          eventCategory={analyticsUtils.categories.WHATS_HAPPENING_CENTER}
          eventAction={analyticsUtils.actions.CLICK}
        >
          <ol className={style.deckCardList} aria-labelledby={id} role="list">
            {React.Children.map(children, child => {
              if (child === undefined || child === null) {
                return undefined
              }
              return React.cloneElement(child as JSX.Element, {
                className: style.deckCard,
                onClick: () =>
                  trackPromotionClickEvent((child as JSX.Element).props),
                padded,
              })
            })}
          </ol>
        </DeckScrollWrapper>
        {showCTA && centre && (
          <div className={style.callToActionLink}>
            <Link
              to={callToActionAddress}
              onClick={() => trackDeckCTAClick(callToActionLabel)}
            >
              {callToActionLabel}
            </Link>
          </div>
        )}
      </Container>
    )
  }
}

export { Deck, DeckCard }
