import React from 'react'
import { Banner } from '@scentregroup/shared/hub-components/banner'
import { AsMeasured } from '@scentregroup/shared/hub-components/as-measured'
import { useAnalytics } from '@scentregroup/shared/analytics'

import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { ModuleProps } from '../../types'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'
import { TrackBox } from '@scentregroup/shared/analytics-observer'

function BannerModule(
  props: ModuleProps<SGPageTemplateContentCollection>
): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  if (props.module.items.length === 0) {
    return null
  }

  const item = props.module.items[0]

  const triggerOnClick = (): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(props.module, 0),
    })
  }
  // SGA11y: Images must have alternate text
  if (!item.image.alt) {
    item.image.alt = item.title || item?.analyticsMetadata?.title
  }

  if (item.secondaryImage && !item.secondaryImage.alt) {
    item.secondaryImage.alt = item.title || item?.analyticsMetadata?.title
  }

  return (
    <TrackBox>
      <Banner
        as={(asProps: any) => (
          <AsMeasured
            {...asProps}
            metadata={item.analyticsMetadata}
            slot={props.module.analyticsMetadata?.displayId}
            moduleKind={props.module.kind}
            moduleMetadata={props.module.analyticsMetadata}
          />
        )}
        image={item.image}
        url={item.callToAction.url}
        onClick={triggerOnClick}
        mobileImage={item.secondaryImage}
      />
    </TrackBox>
  )
}

export default BannerModule
