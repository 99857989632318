import React from 'react'
import { forwardRef, HubStyleObject, As } from '@hub/design-system-base'
import { Text } from '@hub/text'
import { Image } from '@hub/image'
import { Link, LinkBox, LinkOverlay } from '@hub/link'
import { Stack } from '@hub/stack'
import { Box } from '@hub/box'
import { CloudinaryImage, IMAGE_SIZES_DECK_REEL } from '../cloudinary-image'
import { SGPageTemplateContentEntry } from '@scentregroup/shared/types/page-templates'
import MemberExclusiveLabel from '../../components/member-exclusive-label'
import { isMemberExclusive } from '../../helpers/is-member-exclusive'
import { H5 } from '@hub/heading'

export const InlineDeckItem = forwardRef<
  (SGPageTemplateContentEntry & {
    displayDate?: string
    elevateHeading?: boolean
  }) & {
    sx?: HubStyleObject
    imageRef?: React.ElementRef<typeof Image>
    as?: As
  },
  typeof LinkBox
>((item, ref) => {
  const {
    image,
    title,
    shortDescription,
    displayDate,
    callToAction,
    sx,
    imageRef,
    as,
    onClick,
    highlightAttribute,
    elevateHeading,
  } = item

  // SGA11y: Images must have alternate text
  if (!image.alt) {
    image.alt = title
  }

  return (
    <LinkBox ref={ref} sx={sx} as={as} onClick={onClick}>
      <Box sx={{ position: 'relative' }}>
        <CloudinaryImage
          ratio="1"
          imageSetOrImage={image}
          imageRef={imageRef}
          sizes={IMAGE_SIZES_DECK_REEL}
        />
        <MemberExclusiveLabel
          sx={{ position: 'absolute', bottom: 0 }}
          display={isMemberExclusive(highlightAttribute)}
          option="small"
        />
      </Box>
      <Stack gap="spacing-xs" sx={{ paddingTop: 'spacing-md' }}>
        <LinkOverlay href={callToAction.url}>
          {/* SGA11y: Heading levels should only increase by one */}
          {/* If `elevateHeading` is true, we need to render an `h2` to maintain proper heading level hierarchy for accessibility (A11y) */}
          <H5 as={elevateHeading ? 'h2' : 'h4'}>{title}</H5>
        </LinkOverlay>
        {shortDescription && <Text noOfLines={4}>{shortDescription}</Text>}
        {displayDate && <Text>{displayDate}</Text>}
        {!displayDate && !shortDescription && (
          <Link hasUnderline href={callToAction.url}>
            {callToAction.label}
          </Link>
        )}
      </Stack>
    </LinkBox>
  )
})
