import React from 'react'
import { Stack } from '@hub/stack'
import { H3 } from '@hub/heading'
import { Text } from '@hub/text'
import { LinkOverlay } from '@hub/link'
import { Grid } from '@hub/grid'
import {
  DEFAULT_BREAKPOINT,
  useBreakpoint,
  useToken,
  forwardRef,
} from '@hub/design-system-base'
import { CoreContainer } from '@hub/core-container'
import trackClickEvents, {
  categories,
  labels,
} from '@scentregroup/shared/hub-components/analytics/trackEvents'
import {
  CloudinaryImage,
  IMAGE_SIZES_COLOR_CARD_HERO,
} from '../cloudinary-image'
import { ColorCardProps, ColorCardsProps } from './types'
import { ColorCardContent } from './color-card-content'

export const ColorCards: React.FC<React.PropsWithChildren<ColorCardsProps>> = ({
  title,
  description,
  children,
  hasOuterMargins = true,
  enableH1Heading,
  as,
}) => {
  const breakpoint = useBreakpoint(DEFAULT_BREAKPOINT)

  return (
    <CoreContainer gutter={hasOuterMargins ? undefined : 'size-none'} as={as}>
      <Stack
        gap="spacing-md"
        align={['center', 'center', 'unset']}
        sx={
          hasOuterMargins ? { marginX: [null, null, null, '10%'] } : undefined
        }
      >
        {/* custom inset chosen by design to create visual rhythm on lg, xl, 2xl */}
        {title || description ? (
          <Stack align="center">
            {title && <H3 as={enableH1Heading ? 'h1' : 'h3'}>{title}</H3>}
            {description && (
              <Text color="quartz" align="center" sx={{ maxWidth: '60ch' }}>
                {description}
              </Text>
            )}
          </Stack>
        ) : null}

        <Grid templateColumns={['1fr', null, '1fr 1fr']} gap="spacing-md">
          {/* if there is only 1 child, show it full width and make it span two columns on desktop/tablet */}
          {React.Children.map(children, (child, index) => {
            if (React.isValidElement<ColorCardProps>(child)) {
              return React.cloneElement(child, {
                isFullWidth:
                  React.Children.count(children) === 1 &&
                  ['2xl', 'xl', 'lg', 'md'].includes(
                    breakpoint || DEFAULT_BREAKPOINT
                  ),
                sx: {
                  ...(React.Children.count(children) === 1 &&
                  ['2xl', 'xl', 'lg', 'md'].includes(
                    breakpoint || DEFAULT_BREAKPOINT
                  )
                    ? {
                        ...(child.props.sx ? child.props.sx : {}),
                        gridColumn: ['span 1', null, 'span 2'],
                      }
                    : {}),
                },
                position: index + 1,
              })
            } else {
              return child
            }
          })}
        </Grid>
      </Stack>
    </CoreContainer>
  )
}

export const ColorCard = forwardRef<ColorCardProps, typeof Stack>(
  (
    {
      isFullWidth = false,
      title,
      image,
      shortDescription,
      callToAction,
      supplementaryText,
      position,
      tag,
      sx,
      as,
      clickEvent,
    },
    ref
  ) => {
    const defaultSupportingColor = useToken('colors', 'surfaceBrandPrimary')
    const squareImagePadding = useToken('space', 'spacing-md')
    const supportingColor = image.supportingColour || defaultSupportingColor

    const LinkOverlayAs = React.useMemo(
      () => (asProps: { href: string }) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-has-content
        <a
          {...asProps}
          onClick={() => {
            if (clickEvent) {
              clickEvent(position ? position - 1 : 0)
            }
            trackClickEvents.clicked(
              categories.COLOUR_CARD,
              labels.createTitleAndPosition(title, position ?? 1)
            )
          }}
        />
      ),
      [clickEvent, position, title]
    )

    // SGA11y: Images must have alternate text
    if (!image.alt) {
      image.alt = title
    }

    return (
      <Stack
        ref={ref}
        sx={{
          backgroundColor: supportingColor,
          padding: 'spacing-md',
          maxWidth: ['456px', null, 'size-full'],
          minWidth: [
            `calc(100vw - (5 * ${squareImagePadding}))`,
            '424px',
            'auto',
          ],
          ...sx,
        }}
        as={as}
        gap="spacing-md"
        shouldWrapChildren={false}
        direction={isFullWidth ? 'row' : 'column'}
      >
        {callToAction ? (
          <Stack
            sx={{
              position: 'relative',
              ...(isFullWidth ? { flexBasis: '33%' } : {}),
            }}
          >
            <LinkOverlay href={callToAction.url} as={LinkOverlayAs}>
              <CloudinaryImage
                ratio="1"
                imageSetOrImage={image}
                maxWidth="456px"
                sizes={IMAGE_SIZES_COLOR_CARD_HERO}
              />
            </LinkOverlay>
          </Stack>
        ) : (
          <CloudinaryImage
            ratio="1"
            imageSetOrImage={image}
            sx={{ ...(isFullWidth ? { flexBasis: '33%' } : {}) }}
            maxWidth="456px"
            sizes={IMAGE_SIZES_COLOR_CARD_HERO}
          />
        )}
        <Stack
          gap="spacing-xs"
          justify={isFullWidth ? 'center' : 'space-between'}
          sx={{
            ...(isFullWidth
              ? {
                  alignContent: 'center',
                  textAlign: 'center',
                  flexBasis: '70%',
                }
              : { flexGrow: 1, height: 'size-full' }),
          }}
          shouldWrapChildren={false}
        >
          <ColorCardContent
            isFullWidth={isFullWidth}
            title={title}
            shortDescription={shortDescription}
            callToAction={callToAction}
            supplementaryText={supplementaryText}
            supportingColor={supportingColor}
            position={position}
            tag={tag}
            clickEvent={() => {
              if (clickEvent) {
                clickEvent(position ? position - 1 : 0)
              }
            }}
          />
        </Stack>
      </Stack>
    )
  }
)
