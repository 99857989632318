import React, { useCallback, useMemo } from 'react'
import canonicalize from 'canonicalize'

import {
  SGPageTemplateContentEntry,
  SGPageTemplateContentCollectionKind,
  SGPageTemplateDisplayAnalyticsMetadata,
} from '@scentregroup/shared/types/page-templates'
import {
  ContentImpressionItem,
  AnalyticsEvent,
  AnalyticsEventWithKey,
} from '../../analytics'
import { trackingNullValue } from '../../constants'
import { TrackBox } from '../../analytics-observer'

// Only works where baseEvent's properties are all stable
// for a given component.
const addKey = (event: AnalyticsEvent): AnalyticsEventWithKey => {
  // uniqunessKey should be a string as event !== undefined
  const uniquenessKey = canonicalize(event) as string
  return { ...event, uniquenessKey }
}

const makeBasePromotionEvent = (
  action: 'Viewed' | 'Selected',
  promotionItem: ContentImpressionItem
): AnalyticsEvent => ({
  object: 'Content Impression',
  action,
  properties: {
    items: [promotionItem],
  },
})

export const AsMeasured: React.FC<
  React.PropsWithChildren<{
    metadata?: SGPageTemplateContentEntry['analyticsMetadata']
    orchestrationMetadata?: SGPageTemplateContentEntry['orchestrationMetadata']
    moduleMetadata?: SGPageTemplateDisplayAnalyticsMetadata
    slot?: string
    moduleKind?: SGPageTemplateContentCollectionKind
    [key: string]: any
  }>
> = ({ metadata, moduleMetadata, slot, moduleKind, children, ...props }) => {
  const promotionItems = useMemo(
    () => [
      ...(metadata && slot
        ? [
            {
              creative_name: metadata.promotion.creative ?? trackingNullValue,
              promotion_id: metadata.correlationId,
              promotion_name: metadata.title,
              creative_slot: slot,
              location_id: metadata.correlationId,
            },
          ]
        : []),
    ],
    [metadata, slot]
  )
  const moduleEvents: AnalyticsEventWithKey[] = useMemo(
    () => [
      ...(moduleMetadata
        ? [
            addKey({
              object: 'Module',
              action: 'Viewed',
              properties: {
                name: moduleMetadata.position ?? trackingNullValue,
                count: moduleMetadata.correlationIds.length,
                type: moduleKind ?? trackingNullValue,
              },
            } as const),
          ]
        : []),
    ],
    [moduleMetadata, moduleKind]
  )

  const impressionEvents: AnalyticsEventWithKey[] = useMemo(
    () => [
      ...promotionItems.map(item =>
        addKey(makeBasePromotionEvent('Viewed', item))
      ),
      ...moduleEvents,
    ],
    [promotionItems, moduleEvents]
  )
  const selectionEvents = useMemo(
    () =>
      promotionItems.map(item =>
        addKey(makeBasePromotionEvent('Selected', item))
      ),
    [promotionItems]
  )
  const onClickTrackEvent = useCallback(
    () => selectionEvents,
    [selectionEvents]
  )
  const onImpressionTrackEventOnce = useCallback(
    () => impressionEvents,
    [impressionEvents]
  )
  return (
    <TrackBox
      {...props}
      onClickTrackEvent={onClickTrackEvent}
      onImpressionTrackEventOnce={onImpressionTrackEventOnce}
    >
      {children}
    </TrackBox>
  )
}
