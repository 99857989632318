import React from 'react'
import { Box } from '@hub/box'
import { HubStyleObject } from '@hub/design-system-base'

const flexibleColumnValues = [2, 3] as const
type FlexibleGridMaxColumns = (typeof flexibleColumnValues)[number]

export interface FlexibleGridProps {
  maxColumns?: FlexibleGridMaxColumns
  sx?: HubStyleObject
}

export const FlexibleGrid: React.FC<
  React.PropsWithChildren<FlexibleGridProps>
> = ({ sx, children, maxColumns = 3 }) => {
  if (!flexibleColumnValues.includes(maxColumns)) {
    throw new Error('Not a valid value for flexible grid maximum columns')
  }

  const ratio = maxColumns === 3 ? '33.3%' : '50%'
  const items = React.Children.map(children, child => (
    <Box
      sx={{
        flex: [
          '0 0 100%',
          '0 0 calc(50% - var(--chakra-space-spacing-lg))',
          `0 0 calc(${ratio} - var(--chakra-space-spacing-xl))`,
          `0 0 calc(${ratio} - var(--chakra-space-spacing-5xl))`,
        ],
      }}
    >
      {child}
    </Box>
  ))

  return (
    <Box
      display="flex"
      sx={{
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: ['spacing-lg', 'spacing-lg', 'spacing-xl', 'spacing-5xl'],
        ...sx,
      }}
    >
      {items}
    </Box>
  )
}
