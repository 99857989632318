import { useContext, useMemo, useRef, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { ApolloClient } from '@apollo/client'
import { captureMessage } from '@sentry/nextjs'
import { AdTargetingContext } from '@scentregroup/shared/context'
import {
  SGPageTemplate,
  SGPageTemplateContentEntry,
  SGPageTemplateWithAdZones,
} from '@scentregroup/shared/types/page-templates'
import { getPageTemplateAdZoneContentEntries } from '../fetching'
import { extractAdZonesFromTemplate } from './ad-zone-extraction'
import { replaceAdZonesInTemplate } from './ad-zone-replacement'

export function useResolvedTemplate<TCacheShape>(
  apollo: ApolloClient<TCacheShape>,
  template: SGPageTemplateWithAdZones
): SGPageTemplate {
  const { current: contentEntriesByAdZoneId } = useRef<
    Map<string, SGPageTemplateContentEntry | null>
  >(new Map())

  const [, setGeneration] = useState<number>(0)

  const adZones = useMemo(
    () => extractAdZonesFromTemplate(template),
    [template]
  )
  const adZoneIds = useMemo(() => adZones.map(zone => zone.id), [adZones])
  const adTargeting = useContext(AdTargetingContext)

  useDeepCompareEffect(() => {
    if (adZoneIds.length === 0) {
      return
    }
    void getPageTemplateAdZoneContentEntries(apollo, adZoneIds, adTargeting)
      .catch(error => {
        captureMessage(
          `Error fetching content entry for ad zone: ${error.message}`,
          {
            extra: { adZoneIds, error },
            level: 'warning',
          }
        )
        return null
      })
      .then(contentEntries => {
        if (contentEntries) {
          for (let i = 0; i < adZoneIds.length; i++) {
            const adZoneId = adZoneIds[i]
            const contentEntry = contentEntries[i]
            contentEntriesByAdZoneId.set(adZoneId, contentEntry)
          }
          return setGeneration(n => n + 1)
        }
      })
  }, [adZoneIds])

  return useMemo(
    () => replaceAdZonesInTemplate(template, contentEntriesByAdZoneId),
    [template, contentEntriesByAdZoneId]
  )
}
