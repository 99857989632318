import React from 'react'
import { StandardTextAlignment, HubStyleObject } from '@hub/design-system-base'
import { Stack } from '@hub/stack'
import { Text } from '@hub/text'
import { H2 } from '@hub/heading'
import { Link, LinkOverlay } from '@hub/link'
import trackClickEvents, { categories } from '../analytics/trackEvents'
import MemberExclusiveLabel from '../../components/member-exclusive-label'
import { StandardButton } from '../standard-button'

interface ContentStackProps {
  as?: React.ElementType<any>
  sx?: HubStyleObject
  title: string
  shortDescription?: string
  callToActionLabel?: string
  url?: string
  position: number
  memberExclusive?: boolean
  onClick?: () => void
}

export const ContentStack: React.FC<
  React.PropsWithChildren<ContentStackProps>
> = ({
  as,
  sx,
  title,
  shortDescription,
  callToActionLabel,
  url,
  position,
  memberExclusive,
  onClick,
}) => {
  const align: StandardTextAlignment = ['center', 'center', 'left']
  return (
    <Stack
      as={as}
      shouldWrapChildren={false}
      sx={{
        alignItems: ['center', 'center', 'flex-start'],
        ...sx,
      }}
      gap="spacing-md"
      onClick={onClick}
    >
      <Stack
        shouldWrapChildren={false}
        gap="spacing-md"
        sx={{
          alignItems: ['center', null, 'flex-start'],
        }}
      >
        <MemberExclusiveLabel
          sx={{
            width: '137px',
            marginBottom: 'spacing-md',
          }}
          display={memberExclusive}
          option="large"
        />
        <H2 align={align}>
          {!callToActionLabel && url ? (
            <LinkOverlay href={url}>{title}</LinkOverlay>
          ) : (
            title
          )}
        </H2>
        {shortDescription ? (
          <Text align={align}>{shortDescription}</Text>
        ) : null}
      </Stack>
      {callToActionLabel && url ? (
        <StandardButton
          as={Link}
          variant="outline"
          href={url}
          ariaLabel={callToActionLabel}
          onClick={() =>
            trackClickEvents.clicked(
              categories.FEATURE_CAROUSEL,
              `${title} | P${position}`
            )
          }
        >
          {callToActionLabel}
        </StandardButton>
      ) : null}
    </Stack>
  )
}
