import { ApolloClient } from '@apollo/client'
import { GetNationalStorefrontDocument } from '@scentregroup/shared/graphql'
import { SGPageTemplateWithAdZones } from '@scentregroup/shared/types/page-templates'
import parse from '../parsing'

export async function getNationalStorefront<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  retailerId: string
): Promise<SGPageTemplateWithAdZones | null> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetNationalStorefrontDocument,
    variables: {
      retailerId,
    },
  })

  if (!data?.template) {
    return null
  }
  return parse(data.template)
}
