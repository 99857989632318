import {
  SGPageTemplateAdZone,
  SGPageTemplateModuleWithAdZones,
  SGPageTemplateWithAdZones,
} from '@scentregroup/shared/types/page-templates'

export function extractAdZonesFromTemplate(
  template: SGPageTemplateWithAdZones
): SGPageTemplateAdZone[] {
  return template.modules.flatMap(extractAdZonesFromTemplateModule)
}

export function extractAdZonesFromTemplateModule(
  module: SGPageTemplateModuleWithAdZones
): SGPageTemplateAdZone[] {
  switch (module.__typename) {
    case 'SGPageTemplateContentCollection':
      return module.items.flatMap(item => {
        if (item.__typename === 'SGPageTemplateAdZone') {
          return item
        }
        return []
      })
    default:
      return []
  }
}
