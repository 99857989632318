import React from 'react'
import { Flex as ChakraFlex, FlexProps } from '@chakra-ui/react'
import {
  As,
  HubResponsiveArray,
  HubStyleObject,
  mapRules,
  StandardSizes,
  StandardSpacings,
  forwardRef,
  css,
  useTheme,
} from '@hub/design-system-base'
import { GapContext } from './gap-context'

/**
 * Usage:

import { Cluster, ClusterItem } from '.';

export default () => (
  <Cluster as='ul' gap='1em' minSize='10em'>
    <Box as="li">1. Normal grid item</Box>
    <ClusterItem as='li' width='100%'>2. Full width item</ClusterItem>
    <Box as="li">3. Normal grid item</Box>
    <Box as="li">4. Normal grid item</Box>
    <ClusterItem as='li' width='20em'>5. Double width item</Box>
    <Box as="li">4. Normal grid item</Box>
  </Cluster>
)

Note: Children must be (or extend) a Chakra `<Box>` to ensure styles are applied
correctly.

<ClusterItem> is only necessary if you want to explicitly set the width of an
item, otherwise it's safe to just use a regular element.

 */

interface ClusterProps {
  gap?: HubResponsiveArray<StandardSpacings>
  gapX?: HubResponsiveArray<StandardSpacings>
  gapY?: HubResponsiveArray<StandardSpacings>
  minSize?: HubResponsiveArray<StandardSizes>
  justify?: FlexProps['justify']
  align?: FlexProps['align']
  wrap?: 'wrap' | 'nowrap'
  sx?: HubStyleObject
  role?: string
  as?: As
}

// See https://github.com/Heydon/fukol-grids
export const Cluster = forwardRef<ClusterProps, typeof ChakraFlex>(
  (
    {
      children,
      // Shorthand for specifying `gapX` & `gapY`. Supports resopnsive values.
      gap = 'spacing-none',
      gapX = gap,
      gapY = gap,
      minSize = 'auto',
      justify,
      align,
      wrap = 'wrap',
      sx,
      as,
      role,
    },
    ref
  ) => {
    const theme = useTheme()
    const margins = css({ mx: gapX, my: gapY })(theme)

    const wrapperMargins = mapRules(margins, margin => `calc(${margin} / -2)`)

    const childMargins = mapRules(margins, margin => `calc(${margin} / 2)`)

    return (
      <GapContext.Provider value={{ gapX, gapY }}>
        <ChakraFlex
          role={role}
          ref={ref}
          as={as}
          wrap={wrap}
          justify={justify}
          align={align}
          sx={{
            ...wrapperMargins,
            '& > *': {
              ...childMargins,
              /* see https://github.com/Heydon/fukol-grids/issues/18 */
              minWidth: 0,
              minHeight: 0,
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: minSize,
            },
            ...sx,
          }}
        >
          {children}
        </ChakraFlex>
      </GapContext.Provider>
    )
  }
)

export { ClusterItem } from './cluster-item'
